import React from "react"
import styled from "styled-components"
import { isMobile } from "react-device-detect";

import SEO from "../../components/seo"
import Page from "../../components/layout/Page"

import WhySection from "../../components/sections/WhySection"
import GoogleImage from "../../components/GoogleImage"
import PageHeader from "../../components/layout/PageHeader";

import { devices } from "../../settings"

const PricingPage = styled.div`
  margin-left: 25px;

  @media ${devices.mobile} {
    margin-left: 0px;
  } 

  h3 {
    font-family: "Roboto Slab", "Helvetica", "Arial", sans-serif;
  }

  img.monkey {
    width: 250px;
    margin-top: -50px;
    float: right;

    @media ${devices.mobile} {
      width: 125px;
      margin-top: -0px;
    } 
  }
`

const WhyPricePage = () => {
  return (
    <>
      <SEO page="price" title="Factory Direct Electric Sign Pricing | Free Shipping" />
      <Page breadcrumb={[
        { url: "/", title: "Home"},
        { url: "/why/", title: "Why SignMonkey"},
        { url: null, title: "Pricing" }
      ]}>

        <PageHeader title="Factory Direct Pricing" />

        <PricingPage>
          <GoogleImage 
            src="price-monkey.png" 
            required 
            lazy
            alt="Price Monkey" 
            className="monkey" 
            width={isMobile ? 125:250}
            height={isMobile ? 144:289}
          />

          <p>
            This means you are buying directly from a large manufacturer that builds thousands of electric signs per year. Be assured we 
            have the highest quality control standards. When you sell as many signs as we do, the last thing you want is quality issues.
          </p>

          <h3>Time Tested Techniques</h3>
          <p>
            Using the most modern CNC equipment, we build the highest quality channel letters at the most competitive prices.
          </p>

          <h3>More Than Low Prices</h3>
          <p>
            Although price is what many of our customers know us by, it is not our most important quality. We hold quality of 
            finished goods as more important. To create quality signs, you must use the highest quality materials. Our high volume 
            buying helps us to purchase materials at a lower cost and pass this saving on to you.
          </p>		
        </PricingPage>

        <br />
        <br />
        <WhySection />
      </Page>
    </>
  )
}

export default WhyPricePage
